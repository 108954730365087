import { configureStore, combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from '../pages/dashboard/dashboardSlice';
import authReducer from '../pages/auth/authSlice';
import {persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'

// let { AsyncStorage } = require("async-storage");
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    //storage: storageSession,
};

const reducer = combineReducers({
    dashboard: dashboardReducer,
    auth: authReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);
// export const store = configureStore({
//     reducer: {
//         dashboard: dashboardReducer,
//     },
// });

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);


export default store