import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    email: null
}


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        updateEmail: (state, action) => {
            state.email = action.payload;
        },

    }
})

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectEmail = (state) => state.auth.email;

export const {updateEmail, updateIsAuthenticated} = authSlice.actions;

export default authSlice.reducer;