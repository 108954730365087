import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    selectTransactionDetail,
    updateTransactionDetail,
    updateOneTransactionToTable,
} from "./dashboardSlice";
import Button from "@material-ui/core/Button";

import {TIMEOUT_IN_MILISECONDS, DASHBOARD_PROCESS_STATUS} from "../../utils/constants";

const axios = require("axios");


export default function TransactionDetail() {
    const transactionDetail = useSelector(selectTransactionDetail);
    const isOpened = transactionDetail.isOpened;

    const dispatch = useDispatch();

    const handleClose = (event) => {
        const transactionDetailNew = {
            isOpened: false,
            data: transactionDetail.data
        };
        dispatch(updateTransactionDetail(transactionDetailNew));
    }

    const handleRetry = () => {
        const newTransactionDetail = {isOpened: true, data: {...transactionDetail.data}};
        newTransactionDetail.data.requestStatus = DASHBOARD_PROCESS_STATUS.inProcess;
        newTransactionDetail.data.requestError = {
            message: null,
            statusCode: null,
            config: null
        }

        dispatch(updateTransactionDetail(JSON.parse(JSON.stringify(newTransactionDetail))));

        let url = process.env.REACT_APP_API_GATEWAY_URL + "/ckostopstatus";

        let params = {
            "checkNumber": transactionDetail.data.checkNumber,
            "rail":"CKO",
            "fsdpPaymentId": transactionDetail.data.fsdpPaymentId,
            "attachmentId":"",
            "status":transactionDetail.data.status
        }
        const headers = {
            'Content-Type': 'application/json',
            'Tenant-Id': transactionDetail.data.tenantId,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
        }


        console.log({url, params, headers})
        // const newTransactionDetail = {isOpened: true, data: {...transactionDetail.data}};

        axios.post(url, params, {headers: headers, timeout: TIMEOUT_IN_MILISECONDS})
            .then(res => {
                if (res.status === 200){
                    console.log("Worked")
                    newTransactionDetail.data.requestStatus = DASHBOARD_PROCESS_STATUS.success;
                    newTransactionDetail.data.requestError = null;
                }
            })
            .catch(error => {
                console.log("Error");
                let requestError = {
                    message: null,
                    statusCode: null,
                    config: null
                }
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    requestError.message = error.response.data.ErrorMessage ? error.response.data.ErrorMessage : error.response.data.message;
                    requestError.statusCode = error.response.status;

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    requestError.message = "The request was made but no response was received.";
                } else {
                    // Something happened in setting up the request that triggered an Error
                    requestError.message = error.message;
                }
                // requestError.config = error.config;
                newTransactionDetail.data.requestStatus = DASHBOARD_PROCESS_STATUS.failed;
                newTransactionDetail.data.requestError = requestError;
                console.log(requestError)
            })
            .then (() => {
                dispatch(updateTransactionDetail(newTransactionDetail));
                dispatch(updateOneTransactionToTable(newTransactionDetail.data));
            });
    }

    return (
        <Dialog open={isOpened} onClose={handleClose}>
            <DialogTitle>Transaction Detail</DialogTitle>
            <DialogContent>
                <div className="border rounded ps-2 ps-2 py-1 mt-2">
                    <div className="row">
                        <div className={"col col-5"}>Transaction Indicator</div>
                        <div className={"col col-7"}>{transactionDetail.data.transactionIndicator}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5"}>Account Number</div>
                        <div className={"col-7"}>{transactionDetail.data.accountNumber}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5"}>Check Number</div>
                        <div className={"col-7"}>{transactionDetail.data.checkNumber}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5"}>Check Date</div>
                        <div className={"col-7"}>{transactionDetail.data.checkDate}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5 text-break"}>Check Amount</div>
                        <div className={"col-7 text-break"}>{transactionDetail.data.checkAmount}</div>
                    </div>
                    <div className="row">
                        <div className={"col col-5 text-break"}>Payment Id (fsdp)</div>
                        <div className={"col col-7 text-break"}>{transactionDetail.data.fsdpPaymentId}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5 text-break"}>Tenant-Id</div>
                        <div className={"col-7 text-break"}>{transactionDetail.data.tenantId}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5 text-break"}>Status</div>
                        <div className={"col-7 text-break"}>{transactionDetail.data.status}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5 text-break"}>Reason</div>
                        <div className={"col-7 text-break"}>{transactionDetail.data.reason}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5 text-break"}>Request Status</div>
                        <div className={"col-7 text-break fw-bold"}  style={{color: "green"}}>{transactionDetail.data.requestStatus}</div>
                    </div>
                    <div className="row">
                        <div className={"col-5 text-break"}>Request Error</div>
                        <div className={"col-7 text-break"} title={JSON.stringify(transactionDetail.data.requestError)}>{transactionDetail.data.requestError && transactionDetail.data.requestError.message}</div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {(transactionDetail.data.requestStatus === DASHBOARD_PROCESS_STATUS.failed || transactionDetail.data.requestStatus === DASHBOARD_PROCESS_STATUS.inProcess) && (
                    <Button onClick={handleRetry}>Retry</Button>
                )}
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}