import React, {useState, Fragment} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {CSVReader} from "react-papaparse";


function FileUploadBtn(props) {
    const {isValidFileFormat, DataPreview, setUploadedData, classList, setUploadedFileInput, dialogTitleText} = props

    const [open, setOpen] = useState(false);

    // const [uploadedData, setUploadedData] = React.useState(null);
    const [temporaryData, setTemporaryData] = React.useState(null);
    const [isWarning, setIsWarning] = React.useState(false);
    const [isSuccessfulDropped, setIsSuccessfulDropped] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMessage(null);

        setIsWarning(false);
        setIsSuccessfulDropped(false);

        setTemporaryData(null);

    };

    const handleConfirm = () => {
        setOpen(false);

        setIsWarning(false);
        setIsSuccessfulDropped(false);

        setUploadedData(temporaryData);
    }

    const handleOnDrop = (data) => {
        let inputFile = document.querySelector('div.csv-file-upload input').files[0];
        setUploadedFileInput(inputFile);
        console.log("handle drop file", {inputFile})
        if (!inputFile.name.endsWith('.csv')) {
            setErrorMessage("Support only CSV file");
            return;
        }
        if (isValidFileFormat(data)){
            setTemporaryData(data);
            setIsSuccessfulDropped(true);
            setErrorMessage(null);
        } else {
            setErrorMessage("Field(s) missing and/or order mismatch")
        }

    }

    const handleOnError = (err, file, inputElem, reason) => {
        setErrorMessage(reason);
    }

    const handleOnRemoveFile = (data) => {
        setTemporaryData(null);
        setIsSuccessfulDropped(false);
        setErrorMessage(null);
    }

    return (
        <div className={classList.container}>
            <button type="submit" className={"btn " + classList.btnType} onClick={handleClickOpen}>
                {props.btnText}
            </button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogTitleText}</DialogTitle>
                {!isWarning && (
                    <Fragment>
                        <DialogContent>
                            <DialogContentText>
                                Please upload the csv file.
                            </DialogContentText>

                            <div className="csv-file-upload">
                                <CSVReader
                                    onDrop={handleOnDrop}
                                    onError={handleOnError}
                                    addRemoveButton
                                    removeButtonColor='#659cef'
                                    onRemoveFile={handleOnRemoveFile}
                                >
                                    <span>Drop CSV file here or click to upload.</span>
                                </CSVReader>
                            </div>
                            

                            <DialogContentText><span className={"mt-4 text-danger"}>{errorMessage}</span></DialogContentText>

                            {isSuccessfulDropped && <Fragment>
                                <DialogContentText>Preview</DialogContentText>
                                <DataPreview data={temporaryData}/>
                            </Fragment>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={()=> {setIsWarning(true)}} disabled={!(isSuccessfulDropped && errorMessage == null)} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}
                {isWarning && (
                    <Fragment>
                        <DialogContent>
                            <DialogContentText>Are you sure to continue?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}

            </Dialog>
        </div>
    )
}

export default FileUploadBtn