import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export default function ApiErrorDialog(props) {
  
  
  const {elementId, isOpen, dialogTitle, dialogContent, handleRetry, onDialogOpened, onDialogOpen, onDialogClosed} = props;

  const [open, setOpen] = React.useState(isOpen);

  const handleClickOpen = () => {
    onDialogOpen && onDialogOpen();
    setOpen(true);
    onDialogOpened && onDialogOpened();
  };

  const handleClose = () => {
    setOpen(false);
    onDialogClosed && onDialogClosed();
  };

  return (
    <div>
      <Button id={elementId+"-alert-dialog-btn"} style={{display: "none"}} variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={elementId+"-alert-dialog-title"}
        aria-describedby={elementId+"-alert-dialog-description"}
      >
        <DialogTitle id={elementId+"-alert-dialog-title"}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={elementId+"-alert-dialog-description"}>
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {handleRetry && <Button className="MuiButton-textPrimary" onClick={handleRetry}>Retry</Button>}
          <Button className="MuiButton-textPrimary" onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
