import React, {useEffect, useState} from "react";
import FileUploadBtn from "../../components/templates/FileUploadBtn";
import { LEGAL_ENTITY_PROPERTIES, LEGAL_ENTITY_PROPERTIES_MODIFIED } from "../../utils/constants";
import { selectApiErrorResponse, selectLegalEntityTableData, updateApiErrorResponse, updateLegalEntityTable, updateOneTransactionToTable } from "../dashboard/dashboardSlice";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import ApiErrorDialog from "../../components/utils/ApiErrorDialog";

const isValidFileFormat = (data) => {
    if (data===null || data.length <=0 || data[0].data.length !== LEGAL_ENTITY_PROPERTIES.length) {
        return false;
    }

    let titleArr = data[0].data;

    for (let index=0; index <data[0].data.length; index++){
        if (titleArr[index] !== LEGAL_ENTITY_PROPERTIES[index]) {
            return false;
        }
    }

    return true;
}

const isValidLegalEntity = (legalEntity) => legalEntity.length === LEGAL_ENTITY_PROPERTIES.length;

const convertDataToTable = (data) => {
    let result = [];
    for (let i=1; i<data.length; i++) {
        let legalEntity = data[i].data;
        if (isValidLegalEntity(legalEntity)){
            let newLegalEntity = {};
            LEGAL_ENTITY_PROPERTIES.forEach((prop, index) => {
                newLegalEntity[prop] = legalEntity[index];
            })

            result.push(newLegalEntity);
        }
    }
    return result;
}

const RowItem = (props) => {
    const {item} = props;
    const MaxStringLength = 30;
    const shortenString = (str) => {
        return str.length > MaxStringLength ? str.substr(0, MaxStringLength) + "..." : str;
    }
    return (
        <div className="border rounded d-flex justify-content-between ps-2 ps-2 py-1 mt-2" key={item.legalEntityId}>
            {LEGAL_ENTITY_PROPERTIES.map((property, index) => <div className="col" key={"prop-"+index} style={{paddingLeft: "6px", paddingRight: "6px"}}>{shortenString(item[property])}</div>)}
        </div>);
};

const DataPreview = (props) => {
    const data = convertDataToTable(props.data);

    const ListItems = data.map((item, index) => <RowItem item={item}/>);
    return (
        <div>
            <div id={"preview-table"} style={{minWidth: "6200px"}}>
                <div className="border rounded d-flex justify-content-between ps-2 ps-2 py-1 fw-bold">
                    {LEGAL_ENTITY_PROPERTIES_MODIFIED.map((item, index) => <div key={"prop-header-"+index} className="col" style={{paddingLeft: "6px", paddingRight: "6px"}}>{item}</div>)}
                </div>
                {ListItems}
            </div>
        </div>

    )
}

const initializeLegalEntityTable = (data, dispatch) => {
    let result = [];
    for (let i=0; i<data.length; i++) {
        let payments = data[i];
        result.push({...payments, status: ""});
    }
    dispatch(updateLegalEntityTable(result));
}

const classList = {
    container: "me-3",
    btnType: "btn-dark"
}

const updateStatusForLegalEntities = (newLegalEnities, tableData) => {
    let newTableData = [];

    tableData.forEach((item) => {
        newTableData.push({...item})
    })

    newLegalEnities.forEach((entity, index) => {
        newTableData[index].status = entity.status;
        // for (let item in newTableData) {
        //     if (newTableData[item]['legalEntityId'] === entity.legalEntityId && newTableData[item]['supportedRails'] === entity.supportedRails) {
        //         newTableData[item].status = entity.status;
        //         break;
        //     }
        // }
    })


    return newTableData;
} 

const addLegalEntity = (updatedFileInput, tableData, dispatch, setOpenApiErrorDialog) => {

    if (updatedFileInput == null) return;

    let formData = new FormData();
    formData.append('file', updatedFileInput);

    let url = process.env.REACT_APP_API_ADD_LEGAL_ENTITY + '/add'

    let configs;
    configs = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 10000
    }

    console.log("URL", url);

    axios.post(url, formData, configs).then(res => {
        if (res.status === 200){
            console.log("Successfull", {res});
            const legalEntities = res.data.legalEntities;
            dispatch(updateLegalEntityTable(updateStatusForLegalEntities(legalEntities, tableData)));
        }
    }).catch(error => {
        let requestError = {
            message: null,
            statusCode: null,
            config: null,
            url
        }
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            requestError.message = error.response.data.ErrorMessage ? error.response.data.ErrorMessage : error.response.data.message;
            requestError.statusCode = error.response.status;

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            requestError.message = "The request was made but no response was received.";
        } else {
            // Something happened in setting up the request that triggered an Error
            requestError.message = error.message;
        }
        // payment.requestStatus = DASHBOARD_PROCESS_STATUS.failed;
        // payment.requestError = requestError;
        // console.log(payment.requestError)
        console.log("Error:", {requestError});
        dispatch(updateApiErrorResponse(requestError));
        setOpenApiErrorDialog(true);
    })

}

function AddLegalEntityBtn() {
    const [uploadedData, setUploadedData] = useState(null);
    const [updatedFileInput, setUpdatedFileInput] = useState(null);
    const tableData = useSelector(selectLegalEntityTableData);

    const [openApiErrorDialog, setOpenApiErrorDialog] = useState(false);

    const apiErrorResponse = useSelector(selectApiErrorResponse);

    const dispatch = useDispatch();

    useEffect(() => {
        
        if (uploadedData != null) {
            //Display all requests on the table
            const convertedTableData = convertDataToTable(uploadedData);
            initializeLegalEntityTable(convertedTableData, dispatch);


            //Call api to add legal entity
            addLegalEntity(updatedFileInput, convertedTableData, dispatch, setOpenApiErrorDialog);
        }
    }, [uploadedData])

    return (
        <div>
            <FileUploadBtn
                btnText="Add Legal Entity"
                DataPreview={DataPreview}
                setUploadedData={setUploadedData}
                isValidFileFormat={isValidFileFormat}
                classList={classList}
                setUploadedFileInput={setUpdatedFileInput}
                dialogTitleText="Add Legal Entity"
            />

            {openApiErrorDialog && <ApiErrorDialog 
                isOpen={openApiErrorDialog}
                elementId="add-legal-entity"
                dialogTitle="API Request Failure"
                dialogContent={apiErrorResponse && apiErrorResponse.message}
                onDialogClosed={()=> {setOpenApiErrorDialog(false)}}
            />}
        </div>
        
    )
}

export default AddLegalEntityBtn