import React from "react";
import {useDispatch} from "react-redux";

import {updateTransactionDetail} from "./dashboardSlice";

export const RowItem = (props) => {
    // const dispatch = useDispatch();

    const shortenString = (str) => {
        return str ? str.substr(0, 8) + "..." : "";
    }
    if (props.item.requestStatus === "In Process"){
        // dispatch(stopTransaction(props.item));
    }
    return (
        <div className="border rounded d-flex justify-content-between ps-2 ps-2 py-1 mt-2"
             onClick={props.handleClick}>
            <div className="col">{props.item.transactionIndicator}</div>
            <div className="col">{props.item.accountNumber}</div>
            <div className="col">{props.item.checkNumber}</div>
            <div className="col">{props.item.checkDate}</div>
            <div className="col">${props.item.checkAmount}</div>
            <div className="col" title={props.item.fsdpPaymentId}>{shortenString(props.item.fsdpPaymentId)}</div>
            <div className="col" title={props.item.tenantId}>{shortenString(props.item.tenantId)}</div>
            <div className="col">{props.item.status}</div>
            <div className="col">{props.item.reason}</div>
            <div className="col" style={{color: "green"}}
                 title={props.item.requestError && props.item.requestError.message}>{props.item.requestStatus}</div>
            <input type="hidden" value={JSON.stringify(props.item)}/>
        </div>);
};

export default function Table(props) {
    const dispatch = useDispatch();

    const openTransactionDetail = (event) => {
        let selectedTransactionEles = event.target.parentNode.childNodes;
        let inputNode = selectedTransactionEles[selectedTransactionEles.length -1];
        let transaction = {
            isOpened: true,
            data: JSON.parse(inputNode.value)
        }
        dispatch(updateTransactionDetail(transaction))
    }





    const ListItems = props.data.map((item) => <RowItem item={item} handleClick={openTransactionDetail}/>);
    //     <div className="border rounded d-flex justify-content-between ps-2 ps-2 py-1">


    return (
        <div className="bg-light  pb-5">
            <div id="pageContent" className="container max-w-1k pt-3">

                <div id="tableContent" className="pt-3">
                    <div className="border rounded bg-dark d-flex justify-content-between ps-2 ps-2 py-1" style={{color: "white"}}>
                        <div className="col">Transaction Indicator</div>
                        <div className="col">Account Number</div>
                        <div className="col">Check Number</div>
                        <div className="col">Check Date</div>
                        <div className="col">Check Amount</div>
                        <div className="col">Payment Id (fsdp)</div>
                        <div className="col">Tenant-Id</div>
                        <div className="col">Status</div>
                        <div className="col">Reason</div>
                        <div className="col">Request Status</div>
                    </div>
                    {ListItems}
                </div>
            </div>
        </div>
    )
}