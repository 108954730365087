import React from "react";
import {useDispatch} from "react-redux";
import { LEGAL_ENTITY_PROPERTIES, LEGAL_ENTITY_PROPERTIES_MODIFIED } from "../../utils/constants";

import { updateLegalEntityDetail } from "./dashboardSlice";

export const RowItem = (props) => {
    const {item} = props;
    const MaxStringLength = 130;
    const shortenString = (str) => {
        return str.length > MaxStringLength ? str.substr(0, MaxStringLength) + "..." : str;
    }
   
    if (props.item.requestStatus === "In Process"){
        // dispatch(stopTransaction(props.item));
    }
    return (
        <div key={"row-item-2"+item[LEGAL_ENTITY_PROPERTIES[0]]} className="border rounded d-flex justify-content-between ps-2 pe-2 py-1 mt-2 table-row table-body" onClick={props.handleClick}>
            {LEGAL_ENTITY_PROPERTIES.map((property, index) => <div className={"col table-cell col-num-"+index}>{shortenString(item[property])}</div>)}
            
            <div className={"col table-cell"} style={{}}>
                <span class={props.item.status && props.item.status.message.toLowerCase()}>
                    {props.item.status && props.item.status.message}
                </span>
            </div>
            <div className="col table-cell" style={{}}>{props.item.status && props.item.status.errorMessage}</div>
            {/* <div className="col table-cell" style={{color: "green"}}
                 title={props.item.requestError && props.item.requestError.message}>{props.item.requestStatus}</div> */}
            <input type="hidden" value={JSON.stringify(props.item)}/>
        </div>);
};

export default function LegalEntityTable(props) {
    const dispatch = useDispatch();


    const openLegalEntityDetail = (event) => {
        event.preventDefault();
        let inputNode;
        let childNodes;
        if (event.target.classList.contains('table-body')) {
            childNodes = event.target.childNodes;    
        } else {
            childNodes = event.target.parentNode.childNodes;
        }
        
        inputNode = childNodes[childNodes.length -1];
        let legalEntityDetail = {
            isOpened: true,
            data: JSON.parse(inputNode.value)
        }
        dispatch(updateLegalEntityDetail(legalEntityDetail))
    }





    const ListItems = props.data.map((item, index) => <RowItem item={item} handleClick={openLegalEntityDetail}/>);

    return (
        <div className="bg-light  pb-5">
            <div id="pageContent" className="container max-w-1k pt-3">
                <div className="wrapper table-lg">
                    <div id="legal-entity-table" className="pt-3 pb-2" style={{display: "table"}}>
                        <div className="table-header table-row border rounded bg-dark d-flex justify-content-between ps-2 pe-2 py-1" style={{color: "white"}}>
                            {LEGAL_ENTITY_PROPERTIES_MODIFIED.map((property, index) => <div key={"props-header-1-"+index} className={"col table-cell col-num-" + index}>{property}</div>)}
                            <div className="col table-cell">Status</div>
                            <div className="col table-cell">Reason</div>
                        </div>
                        
                        {ListItems}
                        
                    </div>
                </div>
            </div>

        </div>
    )
}