import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/auth/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import Logout from "./pages/auth/Logout";
import AddLegalEntity from "./pages/add-legal-entity/AddLegalEntity";

function App() {
  return (
      <Router>
          <Switch>
              <Route path="/" exact component={Login}/>
              <Route path="/login" exact component={Login}/>
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/legalentity" component={AddLegalEntity} />
              <Route exact path="/homepage" component={Dashboard} />
              <Route path='/logout' exact component={Logout}/>
          </Switch>
      </Router>
  );
}

export default App;
