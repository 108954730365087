import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {CSVReader} from "react-papaparse";
// import {RowItem} from "./DashboardTable";
import axiosRetry from 'axios-retry';

import {updateTable, updateOneTransactionToTable} from "./dashboardSlice";
import {useDispatch} from "react-redux";
// import data from "bootstrap/js/src/dom/data";

import {TIMEOUT_IN_MILISECONDS, TIME_INTERVAL, DASHBOARD_PROCESS_STATUS} from "../../utils/constants";

const axios = require("axios");

const cols = {
    TransactionIndicator: 0,
    AccountNumber: 1,
    CheckNumber: 2,
    CheckDate: 3,
    CheckAmount: 4,
    Narratives: 5,
    Status: 6,
    Reason: 7,
    RequestStatus: 8,
    RequestError: 9
}



const isFailedTransaction = (status) => (status && status.toUpperCase() === "FAILED") ? true : false;

const stopPayments = (data, dispatch) => {

    if (data == null) return;

    let requests = [];
    let url = process.env.REACT_APP_API_GATEWAY_URL + "/ckostopstatus";

    for (let i = 0; i < data.length; i++) {
        console.log("stop payment", data[i]);
        let params, headers;
        params= {
            "checkNumber": data[i].checkNumber,
            "rail": "CKO",
            "fsdpPaymentId": data[i].fsdpPaymentId,
            "attachmentId": "",
            "status": data[i].status
        }

        headers = {
            'Content-Type': 'application/json',
            'Tenant-Id': data[i].tenantId,
        }
        requests.push({index: i, params, headers})

    }


    if (requests){
        axios.all(requests.map(req => {
            // setTimeout(function () {
                let payment = {...data[req.index]};
                console.log({url, params: req.params, headers: req.headers})
                return axios.post(url, req.params, {headers: req.headers, timeout: TIMEOUT_IN_MILISECONDS})
                    .then(res => {
                        if (res.status === 200) {
                            payment.requestStatus = DASHBOARD_PROCESS_STATUS.success;
                            payment.requestError = null;
                        }
                    })
                    .catch(error => {
                        let requestError = {
                            message: null,
                            statusCode: null,
                            config: null
                        }
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            requestError.message = error.response.data.ErrorMessage ? error.response.data.ErrorMessage : error.response.data.message;
                            requestError.statusCode = error.response.status;

                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            requestError.message = "The request was made but no response was received.";
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            requestError.message = error.message;
                        }
                        payment.requestStatus = DASHBOARD_PROCESS_STATUS.failed;
                        payment.requestError = requestError;
                        console.log(payment.requestError)
                    })
                    .then(() => {
                        dispatch(updateOneTransactionToTable(payment));
                    });
            // }, req.index * TIME_INTERVAL)
        })).then()
    }

}

const RowItem = (props) => {
    const shortenString = (str) => {
        return str ? str.substr(0, 8) + "..." : "";
    }
    return (
        <div className="border rounded d-flex justify-content-between ps-2 ps-2 py-1 mt-2" key={props.key}>
            <div className="col col-1">{props.item.transactionIndicator}</div>
            <div className="col col-1">{props.item.accountNumber}</div>
            <div className="col col-1">{props.item.checkNumber}</div>
            <div className="col col-1">{props.item.checkDate}</div>
            <div className="col col-1">${props.item.checkAmount}</div>
            <div className="col col-1">{shortenString(props.item.narratives)}</div>
            <div className="col col-2 text-break" title={props.item.fsdpPaymentId}>{props.item.fsdpPaymentId}</div>
            <div className="col col-1" title={props.item.tenantId}>{shortenString(props.item.tenantId)}</div>
            <div className="col col-1">{props.item.status}</div>
            <div className="col col-1">{props.item.reason}</div>
        </div>);
};

const DataPreview = (props) => {
    const data = convertDataToTable(props.data);

    const ListItems = data.map((item, index) => <RowItem item={item} key={index}/>);
    return (
        <div style={{maxHeight: "600px", overflowX: "auto"}}>
            <div id={"preview-table"} style={{minWidth: "1000px"}}>
                <div className="border rounded d-flex justify-content-between ps-2 ps-2 py-1 fw-bold">
                    <div className="col col-1">Transaction Indicator</div>
                    <div className="col col-1">Account Number</div>
                    <div className="col col-1">Check Number</div>
                    <div className="col col-1">Check Date</div>
                    <div className="col col-1">Check Amount</div>
                    <div className="col col-1">Narratives</div>
                    <div className="col col-2">Payment Id (fsdp)</div>
                    <div className="col col-1">Tenant-Id</div>
                    <div className="col col-1">Status</div>
                    <div className="col col-1">Reason</div>
                </div>
                {ListItems}
            </div>
        </div>

    )
}

const getFsdpPaymentId = (narratives) => narratives && narratives.split("|").length === 2 ? (narratives.split("|"))[0] : "";
const getTenantId = (narratives) => narratives && narratives.split("|").length === 2 ? (narratives.split("|"))[1] : "";

const isValidStopTransaction = (payments) => (payments.length === 8)  ? true : false;

const convertDataToTable = (data) => {
    console.log("convertDataToTable", {data})
    let result = [];
    for (let i=0; i<data.length; i++) {
        //Check the valid stop-payment transaction
        let payments = data[i].data;
        console.log({payments});
        if (isValidStopTransaction(payments)){
            result.push(
                {
                    transactionIndicator: payments[cols.TransactionIndicator],
                    accountNumber: payments[cols.AccountNumber],
                    checkNumber: payments[cols.CheckNumber],
                    checkDate: payments[cols.CheckDate],
                    checkAmount: payments[cols.CheckAmount],
                    fsdpPaymentId: getFsdpPaymentId(payments[cols.Narratives]),
                    narratives: (payments[cols.Narratives]),
                    tenantId: getTenantId(payments[cols.Narratives]),
                    status: payments[cols.Status],
                    requestStatus: payments[cols.RequestStatus],
                    requestError: payments[cols.RequestError],
                    reason: payments[cols.Reason]
                }
            );
        }
    }
    return result;
}


const initializeTable = (data, dispatch) => {
    let result = [];
    for (let i=0; i<data.length; i++) {
        let payments = data[i];
        result.push(
            {
                transactionIndicator: payments.transactionIndicator,
                accountNumber: payments.accountNumber,
                checkNumber: payments.checkNumber,
                checkDate: payments.checkDate,
                checkAmount: payments.checkAmount,
                fsdpPaymentId: getFsdpPaymentId(payments.narratives),
                tenantId: getTenantId(payments.narratives),
                status: payments.status,
                requestStatus: DASHBOARD_PROCESS_STATUS.inProcess,
                requestError: null,
                reason: payments.reason
            }
        );
    }
    dispatch(updateTable(result));
}

const isValidFileFormat = (data) => {
    if (data===null || data.length <=0 || data[0].data.length !== 8) {
        return false;
    } else {
        console.log("Validate", {data});
        return true;
    }
}

export default function UploadedFileForm() {
    const [open, setOpen] = React.useState(false);
    const [uploadedData, setUploadedData] = React.useState(null);
    const [temporaryData, setTemporaryData] = React.useState(null);
    const [isWarning, setIsWarning] = React.useState(false);
    const [isSuccessfulDropped, setIsSuccessfulDropped] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        //Execute the stop-payment requests
        if (uploadedData != null) {
            //Display all requests on the table
            const convertedTableData = convertDataToTable(uploadedData);
            initializeTable(convertedTableData, dispatch);

            //Call api to stop payments
            stopPayments(convertedTableData, dispatch);
        }
    }, [uploadedData])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMessage(null);

        setIsWarning(false);
        setIsSuccessfulDropped(false);

        setTemporaryData(null);

    };

    const handleConfirm = () => {
        setOpen(false);

        setIsWarning(false);
        setIsSuccessfulDropped(false);

        setUploadedData(temporaryData);
    }

    const handleOnDrop = (data) => {
        if (isValidFileFormat(data)){
            setTemporaryData(data);
            setIsSuccessfulDropped(true);
            setErrorMessage(null);
        } else {
            setErrorMessage("The format of file is incorrect.")
        }

    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err, file, inputElem, reason);
        setErrorMessage(reason);
    }

    const handleOnRemoveFile = (data) => {
        setTemporaryData(null);
        setIsSuccessfulDropped(false);
        setErrorMessage(null);
    }

    return (
        <div>
            <button type="submit" className="btn btn-primary" onClick={handleClickOpen}>
                Update Check Status
            </button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update the stop payment requests</DialogTitle>
                {!isWarning && (
                    <Fragment>
                        <DialogContent>
                            <DialogContentText>
                                Please upload the csv file.
                            </DialogContentText>

                            <CSVReader
                                onDrop={handleOnDrop}
                                onError={handleOnError}
                                addRemoveButton
                                removeButtonColor='#659cef'
                                onRemoveFile={handleOnRemoveFile}
                            >
                                <span>Drop CSV file here or click to upload.</span>
                            </CSVReader>

                            <DialogContentText><span className={"mt-4 text-danger"}>{errorMessage}</span></DialogContentText>

                            {isSuccessfulDropped && <Fragment>
                                <DialogContentText>Preview</DialogContentText>
                                <DataPreview data={temporaryData}/>
                            </Fragment>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={()=> {setIsWarning(true)}} disabled={!(isSuccessfulDropped && errorMessage == null)} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}
                {isWarning && (
                    <Fragment>
                        <DialogContent>
                            <DialogContentText>Are you sure to continue?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}

            </Dialog>
        </div>
    );
}
