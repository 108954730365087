import React from "react";
import AddLegalEntityBtn from "../../pages/add-legal-entity/AddLegalEntityBtn";
import UploadedFileForm from "../../pages/dashboard/UploadedFileForm";

export const Header = () => (
    <nav className="container max-w-1k navbar navbar-expand-lg navbar-light mt-2 mb-2">
        <div className="container-fluid">
            <a className="navbar-brand" href="/dashboard" style={{fontFamily:"Tahoma, sans-serif", fontSize: "16px", color: "#0d6efd"}}>
                <img src={"/dream_logo.png"} alt={"Logo"} width="200"/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                <div className="d-flex">
                    <div className="pe-5 border-end">
                        <span className="">Welcome to Support Portal</span>
                    </div>
                    {/*<div className=" d-flex justify-content-center px-4">*/}
                    {/*    /!*<image src={window.location.origin +"/gear-fill.svg"} alt="Bootstrap" width="32" height="32"/>*!/*/}
                    {/*    <div className="m-auto">*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*             className="bi bi-bell" viewBox="0 0 16 16">*/}
                    {/*            <path*/}
                    {/*                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="d-flex justify-content-center px-4">*/}
                    {/*    <div className="m-auto">*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*             className="bi bi-gear-fill" viewBox="0 0 16 16">*/}
                    {/*            <path*/}
                    {/*                d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>*/}
                    {/*        </svg>*/}
                    {/*    </div>*/}
                    {/*    /!*<image src={window.location.origin +"/bell.svg"} alt="Bootstrap" width="64" height="64"/>*!/*/}
                    {/*</div>*/}
                    <div className="">
                        <a className="p-3" href="/logout" style={{textDecoration: "none"}}>Sign Out</a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
)

export const PageTitle = () => (
    <div className="border-light border-bottom border-top border-2">
        <div id="pageTitle" className="container max-w-1k d-flex justify-content-end mt-4 mb-4">

            <div className="btn-group">
                <AddLegalEntityBtn />
                <UploadedFileForm />
            </div>
        </div>
    </div>

)