import React from "react";
import {Header, PageTitle} from "../../components/templates/common";

function AddLegalEntity() {
    return (
        <div>
            <Header />
            <PageTitle />
        </div>
    )
}

export default AddLegalEntity;