export const TIMEOUT_IN_MILISECONDS = 25000;
export const TIME_INTERVAL = 4000;


export const DASHBOARD_PROCESS_STATUS = {
    failed : "Process Failed",
    success: "Process Success",
    inProcess: "In Process"
}

// export const LEGAL_ENTITY_PROPERTIES = ["pmcName", "pmcId", "Record Type", "legalEntityLabel", "legalEntityId", "bankName", "accountNumber", "transitNumber", "institutionNumber", "accountHolderName", "address1", "address2", "zipCode", "city", "province", "countryCode", "contactName", "contactPhone", "contactEmail", "accountId", "tenantId", "accountStatus", "checkHeaderPrintLines", "checkInsertRequired", "checkFormCode", "checkPrintInsert", "SPpartnerBankAccountId", "ACHcompanyId", "SUAaccountPoolId", "enableFuturePayout", "enableReacceptanceAfterReturn"]

// export const LEGAL_ENTITY_PROPERTIES_MODIFIED = ["pmcName", "pmcId", "Record Type", "Legal Entity Label", "Legal Entity ID", "Bank Name", "Account Number", "Transit Number", "Institution Number", "Account Holder Name", "Address 1", "Address 2", "Zip Code", "City", "Province", "Country Code", "Contact Name", "Contact Phone", "Contact Email", "AccountId", "tenantId", "Account Status", "Check Header PrintLines", "Check Insert Required", "Check Form Code", "Check Print Insert", "SPpartner Bank Account Id", "ACH Company ID", "SU Aaccount Pool ID", "Enable Future Payout", "Enable Reacceptance After Return"]


export const LEGAL_ENTITY_PROPERTIES = ["pmcName", "pmcId", "fundingType", "supportedRails", "legalEntityLabel", "legalEntityId", "bankName", "accountNumber", "transitNumber", "institutionNumber", "accountHolderName", "address1", "address2", "zipCode", "city", "province", "countryCode", "contactName", "contactPhone", "contactEmail", "accountId", "tenantId", "accountStatus", "checkHeaderPrintLines", "checkInsertRequired", "checkFormCode", "checkPrintInsert", "SPpartnerBankAccountId", "ACHcompanyId", "SUAaccountPoolId", "enableFuturePayout", "enableReacceptanceAfterReturn"]

export const LEGAL_ENTITY_PROPERTIES_MODIFIED = ["pmcName", "pmcId", "Funding Type", "Supported Rails", "Legal Entity Label", "Legal Entity Id", "Bank Name", "Account Number", "Transit Number", "Institution Number", "Account Holder Name", "Address 1", "Address 2", "Zip Code", "City", "Province", "Country Code", "Contact Name", "Contact Phone", "Contact Email", "Account Id", "Tenant Id", "Account Status", "Check Header Print Lines", "Check Insert Required", "Check Form Code", "Check Print Insert", "SP Partner Bank Account Id", "ACH Company Id", "SUA Account Pool Id", "Enable Future Payout", "Enable Reacceptance After Return"]
