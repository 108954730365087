import {createSlice} from "@reduxjs/toolkit";
import { LEGAL_ENTITY_PROPERTIES} from "../../utils/constants";

const emptyTransactionDetail =
{
    transactionIndicator: null,
    accountNumber: null,
    checkNumber: null,
    checkDate: null,
    checkAmount: null,
    fsdpPaymentId: null,
    tenantId: null,
    status: null,
    requestStatus: null,
    requestError: null,
    reason: null
}

const getEmptyLegalEntity = () => {
    let restult = {};
    LEGAL_ENTITY_PROPERTIES.forEach((property, index) => {
        restult[property] = null
    });
    return restult;
}

const initialState = {
    tableData: [],
    transactionDetail: {
        isOpened: false,
        data: emptyTransactionDetail
    },
    legalEntityTableData: [],
    legalEntityDetail: {
        isOpened: false,
        data: getEmptyLegalEntity()
    },
    apiErrorResponse: null,
    showTable: 0
}

const findAndReplace= (newRow, table) => {
    let res = table.map(row => {
        if (row.fsdpPaymentId === newRow.fsdpPaymentId && row.tenantId === newRow.tenantId) {
            return newRow;
        } else {
            return row;
        }
    })
    return res;
}


const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        updateTable: (state, action) => {
            state.tableData = action.payload;
            state.showTable = 1;
        },
        updateOneTransactionToTable: (state, action) => {
            console.log("updateOneTransactionToTable")
            state.tableData = findAndReplace(action.payload, state.tableData);
        },
        updateTransactionDetail: (state, action) => {
            console.log("updateTransactionDetail")
            state.transactionDetail = action.payload;
        },
        resetTransactionDetail: state => {
            state.transactionDetail = {
                isOpened: false,
                data: emptyTransactionDetail
            }
        },
        updateLegalEntityTable: (state, action) => {
            state.legalEntityTableData = action.payload;
            state.showTable = 2;
        },
        updateLegalEntityDetail: (state, action) => {
            state.legalEntityDetail = action.payload;
        },
        updateShowTable: (state, action) => {
            state.showTable = action.payload
        },
        updateApiErrorResponse: (state, action) => {
            state.apiErrorResponse = action.payload
        }
    }
})

export const selectTableData = (state) => state.dashboard.tableData;
export const selectTransactionDetail = (state) => state.dashboard.transactionDetail;

export const selectLegalEntityTableData = (state) => state.dashboard.legalEntityTableData;
export const selectLegalEntityDetail = (state) => state.dashboard.legalEntityDetail;

export const selectShowTable = (state) => state.dashboard.showTable;
export const selectApiErrorResponse = (state) => state.dashboard.apiErrorResponse;

export const {updateApiErrorResponse, updateTable, updateTransactionDetail, resetTransactionDetail, updateOneTransactionToTable, updateLegalEntityTable, updateLegalEntityDetail, updateShowTable} = dashboardSlice.actions;

export default dashboardSlice.reducer;