import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLegalEntityDetail,
    updateLegalEntityDetail,
} from "./dashboardSlice";
import Button from "@material-ui/core/Button";

import {DASHBOARD_PROCESS_STATUS, LEGAL_ENTITY_PROPERTIES, LEGAL_ENTITY_PROPERTIES_MODIFIED} from "../../utils/constants";

const axios = require("axios");


export default function LegalEntityDetail() {
    const legalEntityDetail = useSelector(selectLegalEntityDetail);
    const isOpened = legalEntityDetail.isOpened;

    const dispatch = useDispatch();

    const handleClose = (event) => {
        const transactionDetailNew = {
            isOpened: false,
            data: legalEntityDetail.data
        };
        dispatch(updateLegalEntityDetail(transactionDetailNew));
    }

    const handleRetry = () => {
        const newEntityDetail = {isOpened: true, data: {...legalEntityDetail.data}};
        newEntityDetail.data.requestStatus = DASHBOARD_PROCESS_STATUS.inProcess;
        newEntityDetail.data.requestError = {
            message: null,
            statusCode: null,
            config: null
        }

        dispatch(updateLegalEntityDetail(JSON.parse(JSON.stringify(newEntityDetail))));

    }

    return (
        <Dialog open={isOpened} onClose={handleClose}>
            <DialogTitle>Legal Entity Detail</DialogTitle>
            <DialogContent>
                <div className="border rounded ps-2 ps-2 py-1 mt-2">
                    {LEGAL_ENTITY_PROPERTIES.map((property, index) => (
                            <div className="row" key={"detail-1-"+index}>
                                <div className={"col col-5"}>{LEGAL_ENTITY_PROPERTIES_MODIFIED[index]}</div>
                                <div className={"col col-7"}>{legalEntityDetail.data[property]}</div>
                            </div>
                        )
                    )}
                    

                    <div className="row">
                        <div className={"col-5 text-break"}>Request Status</div>
                        <div className={"col-7 text-break fw-bold"}>
                            <span>{legalEntityDetail.data.status && legalEntityDetail.data.status.message} - </span>
                            <span>
                                {legalEntityDetail.data.status && legalEntityDetail.data.status.errorMessage}
                            </span>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className={"col-5 text-break"}>Request Error</div>
                        <div className={"col-7 text-break"} title={JSON.stringify(legalEntityDetail.data.requestError)}>{legalEntityDetail.data.requestError && legalEntityDetail.data.requestError.message}</div>
                    </div> */}
                </div>
            </DialogContent>
            <DialogActions>
                {/* {(legalEntityDetail.data.requestStatus === DASHBOARD_PROCESS_STATUS.failed || legalEntityDetail.data.requestStatus === DASHBOARD_PROCESS_STATUS.inProcess) && (
                    <Button onClick={handleRetry}>Retry</Button>
                )} */}
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}