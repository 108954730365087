import React, {useEffect, useState} from "react";
import "../../assets/style/Dashboard.css"
import {
    selectLegalEntityTableData,
    selectShowTable,
    selectTableData
} from "./dashboardSlice"
import {useDispatch, useSelector} from "react-redux";
import TransactionDetail from "./TransactionDetail";
import Table from "./DashboardTable";
import {updateTable} from "./dashboardSlice";
import {DASHBOARD_PROCESS_STATUS} from "../../utils/constants";
import { PageTitle, Header } from "../../components/templates/common";
import LegalEntityTable from "./LegalEntityTable";
import LegalEntityDetail from "./LegalEntityDetail ";

const changeRequestStatusToFailed = (table) => {
    const res = table.map(row => {
        const newRow = {...row}
        if (row.requestStatus && row.requestStatus === DASHBOARD_PROCESS_STATUS.inProcess) {
            newRow.requestStatus = DASHBOARD_PROCESS_STATUS.failed;
            newRow.requestError = {
                message: "The request has not received response."
            }
        };
        return newRow;
    })

    console.log(res);

    return res;
}

function Dashboard(){
    const dispatch = useDispatch();

    const tableData = useSelector(selectTableData);

    const legalEntityTableData = useSelector(selectLegalEntityTableData);

    const tableNumber = useSelector(selectShowTable);

    // changeRequestStatusToFailed(tableData);
    useEffect(()=> {
        //dispatch(updateTable(changeRequestStatusToFailed(tableData)));
    }, [])

    return (
        <div className="">
            <Header />
            <PageTitle />
            {tableNumber === 1 &&  <Table data={tableData}/>}
            {tableNumber === 1 &&  <TransactionDetail />}

            {tableNumber === 2 && <LegalEntityTable data={legalEntityTableData}/>}
            {tableNumber === 2 &&  <LegalEntityDetail />}
{/* 
            {<LegalEntityTable data={legalEntityTableData}/>}
            {<LegalEntityDetail />} */}
        </div>
    )
}

export default Dashboard