import React, {useEffect, useState} from "react";
import "../../assets/style/Login.css"
// import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import {Amplify, Auth} from 'aws-amplify';

import amplifyAuthConfig from "../../services/AmplifyAuthConfig";

Amplify.configure(amplifyAuthConfig);

function Login(){
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isOpened, setIsOpened] = useState(true);
    const [MSFCode, setMSFCode] = useState(undefined);
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmedPassword, setNewConfirmedPassword] = useState("");
    const [errorMessage1, setErrorMessage1] = useState("")
    const [errorMessage2, setErrorMessage2] = useState("");
    const [errorMessage3, setErrorMessage3] = useState("")
    const [isOpenedNewPassword, setIsOpenedNewPassword] = useState(false);
    const [cognitoUser, setCognitoUser] = useState(null);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    let sessionUserAttributes;

    useEffect(() => {
        forceUpdate();
    }, [errorMessage1, errorMessage2,errorMessage3, isOpenedNewPassword])

    const handleRedirectToDashboard = () => {
        localStorage.setItem("user", email);
        localStorage.setItem("isAuthenticated", true);

        setErrorMessage1("");
        setErrorMessage2("");
        setErrorMessage3("");

        window.location.replace("/dashboard")
    }
    //
    // const user = new CognitoUser({
    //     Username: email,
    //     Pool: UserPool
    // })
    //
    // // console.log(user)
    //
    // const authDetails = new AuthenticationDetails({
    //     Username: email,
    //     Password: password
    // })


    function handleSubmit(event) {
        event.preventDefault();

        setErrorMessage1("");

        Auth.signIn(email, password)
            .then(user => {
                console.log(user);
                setCognitoUser(user);
                if (user.challengeName && user.challengeName == "NEW_PASSWORD_REQUIRED"){
                    setIsOpenedNewPassword(true);
                } else {
                    handleRedirectToDashboard();
                }
            })
            .catch(err => {
                setErrorMessage1(err.message);
                console.log('error signing in', err);
            });




        // user.authenticateUser(authDetails, {
        //     onSuccess: data => {
        //         handleRedirectToDashboard();
        //
        //     },
        //
        //     onFailure: err => {
        //         console.error('onFailure', err)
        //         setErrorMessage1(err.message);
        //     },
        //
        //     totpRequired: () => {
        //         setIsOpened(false);
        //         // var challengeAnswer = prompt('Please input the TOTP code.', '');
        //         // user.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
        //     },
        //
        //     newPasswordRequired: function(userAttributes, requiredAttributes) {
        //         console.log('newPasswordRequired-1', {userAttributes, requiredAttributes});
        //
        //         delete userAttributes.email_verified;
        //
        //         setIsOpenedNewPassword(true);
        //         sessionUserAttributes = userAttributes;
        //     }
        // })

    }


    function handleMSFClick(event){
        event.preventDefault();
    //     user.authenticateUser(authDetails, {
    //         onSuccess: data => {
    //             handleRedirectToDashboard();
    //
    //         },
    //
    //         onFailure: err => {
    //             console.error('onFailure', err)
    //             setErrorMessage2(err.message);
    //         },
    //
    //         totpRequired: () => {
    //             // setIsOpened(false);
    //             // var challengeAnswer = prompt('Please input the TOTP code.', '');
    //             user.sendMFACode(MSFCode, this, 'SOFTWARE_TOKEN_MFA');
    //         },
    //
    //         newPasswordRequired: function(userAttributes, requiredAttributes) {
    //             console.log('newPasswordRequired-1', {userAttributes, requiredAttributes});
    //
    //             delete userAttributes.email_verified;
    //
    //             setIsOpenedNewPassword(true);
    //             sessionUserAttributes = userAttributes;
    //         }
    //     })
    }


    const handleUpdateNewPassword = () => {
        setErrorMessage3("");
        let hasError = false;
        const errArray = [];
        if (newPassword.length < 8) {
            hasError = true;
            errArray.push("Password must have more than 8 characters.");
        };

        if (newPassword !== newConfirmedPassword){
            hasError = true;
            errArray.push("Passwords are Not matched.")
        }

        if (hasError){
            setErrorMessage3(errArray.join("\n"));
            return;
        }


        // const { requiredAttributes } = cognitoUser.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        Auth.completeNewPassword(
            cognitoUser,               // the Cognito User Object
            newPassword,       // the new password
            // OPTIONAL, the required attributes
            {
                // email: email,
                //phone_number: cognitoUser.challengeParam.userAttributes.phone_number
            }
        ).then(user => {
            // at this time the user is logged in if no MFA required
            console.log("Change password successfully\n", user);
            setCognitoUser(user);
            handleRedirectToDashboard();
        }).catch(e => {
            console.log(e);
            setErrorMessage3(e.message);
        });

        // user.authenticateUser(authDetails, {
        //     onSuccess: data => {
        //         handleRedirectToDashboard();
        //
        //     },
        //
        //     onFailure: err => {
        //         console.error('onFailure', err)
        //         setErrorMessage3(err.message);
        //     },
        //
        //     totpRequired: () => {
        //         // setIsOpened(false);
        //         // var challengeAnswer = prompt('Please input the TOTP code.', '');
        //         user.sendMFACode(MSFCode, this, 'SOFTWARE_TOKEN_MFA');
        //     },
        //
        //     newPasswordRequired: function(userAttributes, requiredAttributes) {
        //         user.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
        //             onFailure: err => {
        //                 setErrorMessage3(err.message);
        //                 console.log("Failed to change password:", err)
        //             },
        //             onSuccess: data => {
        //                 handleRedirectToDashboard();
        //             }
        //         });
        //     }
        // })


    }

    return (
        <div className="container d-flex justify-content-center" id="login-page">
            {!isOpenedNewPassword && <form onSubmit={handleSubmit}>
                {isOpened && (<div id="loginTier1">
                    <div className="form-tile text-center pb-3 fs-4" style={{fontFamily:"Tahoma, sans-serif", color: "#0d6efd"}}>
                        <img src={"/dream_logo.png"} alt={"Logo"} width="200"/>
                        <div>Insuretech Support Portal</div>
                    </div>
                    <div className="form-text login-error-message" style={{color: "red"}}>{errorMessage1}</div>
                    <div className="form-group py-2">
                        {/*<label className="" htmlFor="exampleInputEmail1">Email</label>*/}
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                               placeholder="Email"
                               onChange={event => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="form-group py-2">
                        {/*<label className="" htmlFor="exampleInputPassword1">Password</label>*/}
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"
                               onChange={event => setPassword(event.target.value)}
                        />
                    </div>
                    <div className="form-check py-2">
                        {/*<input type="checkbox" className="form-check-input" id="exampleCheck1"/>*/}
                        {/*<label className="form-check-label" htmlFor="exampleCheck1">Remember Email</label>*/}
                    </div>
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn btn-primary">Login</button>
                    </div>
                </div>)}

                {!isOpened && (<div id="loginTotp">
                    <div className="form-tile text-center fw-bold">Two-factor Authentication</div>
                    <div className="form-text login-error-message" style={{color: "red"}}>{errorMessage2}</div>
                    <div className="form-group mt-3 mb-2">
                        <div htmlFor="exampleInputPassword1" className="form-text">Please enter TOTP code from your authentication</div>
                        <input type="text" className="form-control" id="totpCode" placeholder=""
                               onChange={event => setMSFCode(event.target.value)}
                        />
                    </div>

                    <div className="d-grid gap-2">
                        <button type="button" className="btn btn-primary"
                                onClick={handleMSFClick}
                        >Verify code</button>
                    </div>
                </div>)}

            </form>}

            {isOpenedNewPassword && <form>
                <div id={"new-pass-dialog"}>
                    <div className="form-tile text-center fw-bold">Update Password</div>
                    <div className="form-text login-error-message" style={{color: "red"}}>{errorMessage3}</div>
                    <div className="form-group mt-3 mb-2">
                        <div className="form-text mb-1">Please enter new password</div>
                        <input type="password" className="form-control" id="totpCode" placeholder="Password"
                               onChange={event => setNewPassword(event.target.value)}
                        />
                        <input type="password" className="form-control mt-1" id="totpCode" placeholder="Confirm Password"
                               onChange={event => setNewConfirmedPassword(event.target.value)}
                        />
                    </div>

                    <div className="d-grid gap-2">
                        <button type="button" className="btn btn-primary"
                                onClick={handleUpdateNewPassword}
                        >Submit</button>
                    </div>
                </div>
            </form>}

        </div>
    );
}

export default Login;